import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import dimensions from "styles/dimensions";
import Layout from "components/Layout";
import PostListItem from "components/PostListItem";
import LinkListItem from "components/LinkListItem";
import Newsletter from "components/newsletter";



const BlogTitle = styled("h1")`
    margin-bottom: 1em;
`



const Articles = ({ posts, meta, links }) => {

  function just_tags(arr) {
    var tag_list = [];
    arr.forEach((item, i) => {
      if (typeof(item.node._meta.tags)!=='undefined') {
        item.node._meta.tags.forEach((tag, i) => {
          if (!tag_list.includes(tag)) {
            tag_list.push(tag);
          }

        },tag_list);

      }
    },tag_list);
    console.log(tag_list);
    return tag_list;
  }


  links=links.filter(item => item['node']['link_author']==='Koby Ofek');
  var posts_and_links=posts.concat(links);
  posts_and_links=posts_and_links.sort((a, b) => (a['node']['post_date'] > b['node']['post_date']) ? -1 : 1)
  var tags = just_tags(posts_and_links);

  var description = 'Articles, writings, musings and thoughts by Koby Ofek'

  return(
    <>
        <Helmet>
            <title>Articles By Koby Ofek</title>
            <meta name="description" content={description} />
            <meta name="og:title" content='Articles By Koby Ofek' />
            <meta name="og:description" content={description} />
            <meta name="og:type" content='website' />
            <meta name="twitter:card" content='summary' />
            <meta name="twitter:creator" content='@kobyof' />
            <meta name="twitter:title" content='Articles By Koby Ofek' />
            <meta name="twitter:description" content={description} />
        </Helmet>

        <Layout>
            <BlogTitle>
                Articles
            </BlogTitle>
{/*
                {posts.map((post, i) => (

                    <PostItems
                        key={i}
                        author='Koby Ofek'
                        category={post.node.post_category}
                        title={post.node.post_title}
                        date={post.node.post_date}
                        description={post.node.post_preview_description}
                        uid={post.node._meta.uid}
                    />
                ))}
                */}
                {posts_and_links.map((postOrLink, i) => {

                  if (typeof(postOrLink.node.link)!=='undefined') {
                    // this means it's a link

                    return (
                      <LinkListItem
                        source='articles'
                        title={postOrLink.node.link_title}
                        uid={postOrLink.node._meta.uid}
                        tags={postOrLink.node._meta.tags}
                        alltags={tags}
                        link={postOrLink.node.link.url}
                        description={postOrLink.node.post_preview_description}
                        post_date={postOrLink.node.post_date}
                        key={i}
                      />
                    )
                } else {

                  return (
                  <PostListItem
                    source='articles'
                    title={postOrLink.node.post_title}
                    uid={postOrLink.node._meta.uid}
                    tags={postOrLink.node._meta.tags}
                    alltags={tags}
                    description={postOrLink.node.post_preview_description}
                    post_date={postOrLink.node.post_date}
                    key={i}
                  />
                )



                  }

                })}
                <br />
                  <Newsletter />


        </Layout>
    </>
);
}

export default ({ data }) => {
    const posts = data.prismic.allPosts.edges;
    const meta = data.site.siteMetadata;
    const links = data.prismic.allLinks.edges;
    if (!posts) return null;

    return (
        <Articles posts={posts} meta={meta} links={links}/>
    )
}

Articles.propTypes = {
    posts: PropTypes.array.isRequired,
    meta: PropTypes.object.isRequired,
    links: PropTypes.array.isRequired
};


export const query = graphql`
  query blogListQuery($first: Int = 10, $last: Int, $after: String, $before: String)  {
        prismic {
          allLinks {
              edges {
                  node {
                    link {
                        ... on PRISMIC__ExternalLink {
                            _linkType
                            url
                        }
                     }
                     link_author
                     link_category
                     link_title
                     post_preview_description
                     post_date
                     _meta {
                       tags
                       uid
                     }
                  }
              }
          }
            allPosts(sortBy: post_date_DESC,first: $first, last: $last, after: $after, before: $before) {
              pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
                edges {
                    node {
                        post_title
                        post_date
                        post_category
                        post_preview_description
                        post_author
                        _meta {
                            uid
                            tags
                        }
                    }
                }
            }
        }
        site {
            siteMetadata {
                title
                description
                author
            }
        }
    }
`
